.header_outer {
  /*background-color: var(--secondary-background-color);*/
  font-family: 'Dela Gothic One', cursive;
  color: var(--primary-text-color);
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: var(--outer-width);
  margin-bottom: var(--section-margin);

}

.header_inner {
  width: var(--inner-width);
  max-width: var(--max-width);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header_inner a{
  text-decoration: none;
  color: var(--primary-text-color);
  
}

.site-name {

  text-decoration: none;
  color: var(--primary-text-color);
  font-size: larger;
  cursor: pointer;
}



.MenuItem:hover {
  background-color: blue;
  color: white;
}

