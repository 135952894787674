.carousel-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50vw;
    max-height: 50vh;
    width: 100%;
    margin-bottom: var(--section-margin);
}

.header-container {
    position: relative;
    width: var(--inner-width);
    max-width: var(--max-width);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 10;
    color: var(--secondary-text-color)

}

.carousel-inner {
    margin-top: 4rem;
    padding: 1rem;
    max-width: var(--max-width);
    width: var(--inner-width);
    position: absolute;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 1rem;


}

.carousel-container img,
.carousel-text {
    opacity: 0;
    transition: opacity 1s;
    z-index: -10;

}

.carousel-image-container {
    position: relative;
    display: flex;
 
    object-position: top;
    margin-left: 1rem;
    height: 40vw;
    max-height: 40vh;
    border-radius: 1rem;
    z-index: -1;
    overflow: hidden;


}



.carousel-container img {
    position: relative;
    object-fit: cover;
    width: 100%;
    object-position: top;
    border: 2px solid var(--primary-text-color);
    border-radius: 1rem;
    object-position: left top;
}

.carousel-text {
    position: relative;
    color: var(--primary-text);
    z-index: 5;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
}

.carousel-text h2 {
    margin: 0;
}


.carousel-text p {
    position: relative;
}

.carousel-image-caption{
    min-height: 6rem;
}
.carousel-image-caption p {
    font-size: smaller;
    text-shadow: 1px 1px 1px darkslategray;
    margin: 0;

}

.carousel-image-caption.fade-in,
.carousel-container img.fade-in,
.carousel-text.fade-in,
.carousel-shape.fade-in,
.carousel-button.fade-in {
    opacity: 1;
    z-index: 10;

}

.carousel-image-caption.fade-out,
.carousel-container img.fade-out,
.carousel-text.fade-out,

.carousel-button.fade-out {
    opacity: 0;
    z-index: -10;

}

.carousel-shape.fade-in {
    transition: visibility 0s 1s;
    visibility: visible;
}

.carousel-button.fade-out {
    transition: all 0s;
}

.carousel-inner button {
    width: 100px;
    height: 2rem;
    background-color: rgb(11, 227, 227, 0.5);
    border: none;
    border-radius: .25rem;
    color: var(--primary-text);
    text-shadow: 1px 1px 2px darkslategray;
    position: relative;
    cursor: pointer;
    z-index: 20;

}











@media screen and (max-width: 860px) {
    .carousel-container{
        min-height: 70vw;
        height: 60vh;
    }
    .carousel-container h1{
        height: 10vh;
    }
    .carousel-text {
        width: 100%;
        height: 40%;

    }

    .carousel-image-caption {
        width: 90%;
    }

    .carousel-inner{
        flex-direction: column-reverse;
        height: 100%;
        margin-top: 2rem;
    }


    .carousel-image-container {

        width: 100%;
        height: 50%;
        overflow: hidden;
        border-radius: 1rem;
        margin: 0;

    }

    .carousel-image img {
        object-fit: cover;
        
    }


}
@media screen and (max-width: 260px) {
    .carousel-inner{

        margin-top: 4rem;
    }
}

