.story {
    display: flex;
    flex-direction: column;
    align-items: center;
    --container-width: 10rem;
}

.chapter {
    margin-bottom: 2rem;
    width: var(--outer-width);
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    margin: 0;
    padding: 0;
}

.image {
    max-width: var(--container-width);
    max-height: var(--container-width);
}

.chapter_inner {
    border: 0.05rem solid var(--primary-text-color);
    border-radius: 1rem;
    word-wrap: break-word;
    background-color: rgb(53, 64, 82, 0.8);
    position: relative;
    width: var(--inner-width);
    max-width: var(--max-width);
}

.parallax_banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 1rem;
}

.banner {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    object-fit: cover;
}

.chapter_textblock {
    z-index: 2;
    padding: 1rem;
    
}

.icon_container {
    position: relative;
    background-color: var(--primary-background-color);
    background-image: url("../images/background.png");
    width: var(--container-width);
    height: var(--container-width);
    margin-bottom: calc(-1 * var(--container-width) / 2);
    margin-left: min(50vw/2, calc(50% - var(--container-width) / 2));
    align-items: center;
    text-align: center;
    border-radius: 50%;
    z-index: 3;
}

@media screen and (max-width: 600px) {
  .image {
    display: none;
  }
  .icon_container{
    display: none;
  }
    
    
}