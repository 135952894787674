.about_outer{
    width: var(--outer-width);
    margin-bottom: var(--section-margin);
    height: 100%;
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
  }
  
  .about_inner {
    width: var(--inner-width);
    max-width: var(--max-width);
    padding: 1rem;

  }
  .about_inner h1 {
    color: var(--secondary-text-color);
    padding-left: 0;
    font-size: 3rem;

  }
  
  .about_inner h3 {
    color: var(--primary-text-color);
    font-size: 1.5rem;
  }

  svg{
    stroke: var(--secondary-background-color);
    fill: var(--primary-text-color);
    color: var(--primary-text-color);
    -webkit-text-stroke-color: var(--primary-text-color);
    vertical-align: middle;
  }
  .about_inner a{
    color: var(--secondary-text-color);
    font-weight: bold;

  }
  @media screen and (max-width: 600px) {
    .about_inner h1 {
      font-size: 2rem;
    }
    .about_inner h3 {
      font-size: 1rem;
    }
  
    
  }