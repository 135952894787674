.splash {
    width: var(--outer-width);
    margin-bottom: var(--section-margin);
    height: 100%;
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
  }
  
  .splash_inner {
    width: var(--inner-width);
    max-width: var(--max-width);
    padding: 1rem;

  }
  .splash_inner h1 {
    color: var(--secondary-text-color);
    padding-left: 0;
    font-size: 3rem;

  }
  
  .splash_inner h3 {
    color: var(--primary-text-color);
    font-size: 1.5rem;
  }
  @media screen and (max-width: 600px) {
    .splash_inner h1 {
      font-size: 2rem;
    }
    .splash_inner h3 {
      font-size: 1rem;
    }
  
    
  }