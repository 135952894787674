footer {
    max-height: 50px;
    min-height: 5vh;
    background-color: var(--dark-blue);
    text-align: center;
    display: flex;
    flex-direction: column;
    
}

footer a{
    color: var(--secondary-text-color);
    font-weight: bold;
}