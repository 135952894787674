@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Libre+Franklin:wght@600&family=Mukta:wght@300&display=swap');


html {

  --unsaturated-background-color: rgb(53, 64, 82);
  --secondary-background-color: #26395a;
  --primary-text-color: #ddeced;
  --secondary-text-color: #add8e6;
  --primary-background-color: #162338;
  --orange: #cc9933;
  --section-margin: 2rem;
  --max-width: 1000px;
  --inner-width: 90vw;
  --outer-width: 100vw;
 
}

body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-background-color);
  background-image: url("./images/background.png");

  color: var(--primary-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


 
}
.approach {
  width: var(--outer-width);
  height: 40%;
  color: var(--primary-text-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  vertical-align: middle;
}

.approach_inner{
  width: var(--inner-width);
  max-width: var(--max-width);
  padding: 1rem;
}
.approach_header h1{
  color: var(--secondary-text-color);
  padding-left: 0;
}
.approach_header h2{
  color: var(--primary-text-color);
  padding-left: 0;
}
.list_title{
  font-weight: bold;
}











